import React from 'react'
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image'

import Container from './container'
import * as styles from './not-found.module.css'

const NotFound = ({ image, title }) => {

  return (
    <div>
      <div className={styles.hero}>
        {image && (
          <GatsbyImage className={styles.image} alt={title} image={image} />
        )}
      </div>
      <div className={styles.title}>404 Not Found</div>
      <div className={styles.message}>
        お探しのページが見つかりませんでした。
      </div>
      <div className={styles.link}>
        <Link to="/">TOPに戻る</Link>
      </div>
    </div>
  )
}

export default NotFound
